<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="退货单ID">
                <el-input v-model="search.tuiHuoDanID" size="small"/>
            </x-search-item>
<!--            <x-search-item label="采购单ID">-->
<!--                <el-input v-model="search.caiGouDanID" size="small"/>-->
<!--            </x-search-item>-->
            <x-search-item label="供货商">
                <el-input v-model="search.gongYingShang" size="small"/>
            </x-search-item>
            <x-search-item label="仓库名称">
                <el-select v-model="search.cangKuMC" size="small">
                    <el-option v-for="item in options" :key="item.cangKuMC" :label="item.cangKuMC"
                               :value="item.cangKuMC"></el-option>
                </el-select>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
<!--            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">
                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>
            </el-popconfirm>-->
        </div>

        <!--        统计区       -->
        <div>
            <el-row>
                <el-col :span="5">
                    <el-card>
                        <div slot="header" class="card-content" style="text-align: center;">订单数</div>
                        <div slot="default" class="card-content" style="text-align: center;">{{ totalOrders }}</div>
                    </el-card>
                </el-col>
                <el-col :span="5">
                    <el-card>
                        <div slot="header" class="card-content" style="text-align: center;">退货商品种数</div>
                        <div slot="default" class="card-content" style="text-align: center;">{{ totalItems }}</div>
                    </el-card>
                </el-col>
                <el-col :span="5">
                    <el-card>
                        <div slot="header" class="card-content" style="text-align: center;">原采购价</div>
                        <div slot="default" class="card-content" style="text-align: center;">{{ originalPrice }}</div>
                    </el-card>
                </el-col>
                <el-col :span="5">
                    <el-card>
                        <div slot="header" class="card-content" style="text-align: center;">退货金额</div>
                        <div slot="default" class="card-content" style="text-align: center;">{{ totalAmount }}</div>
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card>
                        <div slot="header" class="card-content" style="text-align: center;">盈亏金额</div>
                        <div slot="default" class="card-content" style="text-align: center;">{{ profitAmounts }}</div>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column label="序号" type="index" fixed/>
            <el-table-column prop="tuiHuoDanID" label="退货单ID"/>
<!--            <el-table-column prop="caiGouDanID" label="采购单ID"/>-->
            <el-table-column prop="caoZuoRenXingMing" label="操作人姓名"/>
            <el-table-column prop="caoZuoRenID" label="操作人ID"/>
            <el-table-column prop="tuiHuoSJ" label="退货时间"/>
            <el-table-column prop="gongYingShang" label="供货商"/>
            <el-table-column prop="cangKuMC" label="仓库名称"/>
            <el-table-column prop="yuanCaiGouJinE" label="原采购金额"/>
            <el-table-column prop="tuiHuoJinE" label="退货金额"/>
            <el-table-column prop="yingKuiJinE" label="盈亏金额"/>
            <el-table-column prop="heJiZhong" label="合计（种）"/>
            <el-table-column prop="tuiHuoYuanYin" label="退货原因备注"/>
            <el-table-column align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
<!--                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>
                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>
                    </el-popconfirm>-->
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refreshMsg"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/cggl/TuiHuoDan";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/cggl/cgthgl/TuiHuoDanEdit";
    import Detail from "@/view/cggl/cgthgl/TuiHuoDanDetail";

    export default {
        name: "TuiHuoDanList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                options: [],
                search: {
                    tuiHuoDanID: '',
                    caiGouDanID: '',
                    gongYingShang: '',
                    cangKuMC: '',
                },
                totalOrders: '',   //czy //订单数
                totalItems: '',         //退货商品种数
                originalPrice: '',      //原采购价
                totalAmount: '',        //退货金额
                profitAmount: '',       //盈亏金额

            }
        },
        mounted() {
            this.getTongJiXX()
                this.getCKList()
        },
        methods: {
            refreshMsg(){
                this.refresh()
                this.getTongJiXX()
            },
            // 获取仓库下拉选择列表
            getCKList() {
                this.service.getCangKuList().then((response) => {
                    this.options = response.data
                })
            },
            getTongJiXX(){
                this.service.orders().then(res => {
                    this.totalOrders = res.data.totalOrders
                    this.totalItems = res.data.totalItems
                    this.profitAmounts = res.data.profitAmounts
                    this.originalPrice = res.data.originalPrice
                    this.totalAmount = res.data.totalAmount
                })
            }
        }
    }
</script>

<style scoped>
</style>
