<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
               destroy-on-close center append-to-body width="90%">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <tr class="highlighted">
                <td colspan="3">(请先选 供货商 和 仓库名称 后再搜索商品！)</td>
            </tr>
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="操作人姓名" prop="caoZuoRenXingMing" v-show="false">
                <el-input v-model="form.caoZuoRenXingMing" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="供货商" prop="gongYingShang">
                <el-select v-model="form.gongYingShang" size="small" maxlength="36" show-word-limit>
                    <el-option v-for="item in gongHuoShangOptions" :key="item.gongYingShang" :label="item.gongYingShang"
                               :value="item.gongHuoShangMC"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="仓库名称" prop="cangKuMC">
                <el-select v-model="form.cangKuMC" size="small" maxlength="36" show-word-limit>
                    <el-option v-for="item in options" :key="item.cangKuMC" :label="item.cangKuMC"
                               :value="item.cangKuMC"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="退货原因备注" prop="tuiHuoYuanYin">
                <el-input v-model="form.tuiHuoYuanYin" size="small" maxlength="64" show-word-limit/>
            </el-form-item>
            <x-divider title="退货的详情"></x-divider>


            <x-selector
                    placement="center"
                    selector="CaiGouDanXiangQingSelector"
                    @selected="handleClSelectCG"
                    :param="{'gongHuoShang': form.gongYingShang , 'caiGouCangKu':form.cangKuMC}"
                    code-key="id"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round >添加商品</el-button>
            </x-selector>
            <x-row :rows="form.tuiHuoDeXiangQings" :rowModel="tuiHuoDeXiangQingsModel" height="200px" no-add-btn>
                <x-row-cell title="商品名称" read="shangPinMC"/>
                <x-row-cell title="采购数量" read="caiGouSL"/>
                <x-row-cell title="已退货数量" read="yiTuiHuoSL"/>
                <x-row-cell title="可退货数量" read="weiRuKuSL"/>
                <x-row-cell title="原进货价" read="jinHuoJia"/>
                <x-row-cell title="退货数量" prop="tuiHuoSL" width="100">
                    <el-input slot-scope="{row}" v-model="row.tuiHuoSL" size="small" maxlength="8"/>
                </x-row-cell>
                <x-row-cell title="退货价" prop="chuKuJia" width="100">
                    <el-input slot-scope="{row}" v-model="row.chuKuJia" size="small" maxlength="8"/>
                </x-row-cell>
                <x-row-cell title="生产日期" read="shengChanRQ" type="date" value-format="yyyy-MM-dd"/>
                <x-row-cell title="保质期" read="baoZhiQiTian"/>
                <x-row-cell title="规格" read="guiGe"/>
                <x-row-cell title="货号" read="huoHao"/>
                <x-row-cell title="条码" read="tiaoMa"/>
                <x-row-cell title="单位" read="danWei"/>
                <x-row-cell title="退货原因" width="100">
                    <el-input slot-scope="{row}" v-model="row.tuiHuoYuanYin" size="small" maxlength="64"/>
                </x-row-cell>
            </x-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-popconfirm title="请确认采购退货商品信息无误，提交后无法修改，是否继续？" @confirm="save">
                 <el-button type="primary" size="mini" round slot="reference" icon="el-icon-document-add">保 存</el-button>
            </el-popconfirm>

            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/cggl/TuiHuoDan";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {getCangKuList} from "@/service/cggl/TuiHuoDan";
    import {getGongHuoShangList} from "@/service/cggl/CaiGouDan";
    import CaiGouDanXiangQingSelector from "@/view/selector/CaiGouDanXiangQingSelector";

    export default {
        mixins: [XEditBase],
        components: {CaiGouDanXiangQingSelector},
        beforeCreate() {
            let {required, positiveEightTwo, digits} = ruleBuilder;
            this.rules = {
                    tuiHuoDanID: [], // 退货单ID
                    caiGouDanID: [], // 采购单ID
                    caoZuoRenXingMing: [], // 操作人姓名
                    caoZuoRenID: [], // 操作人ID
                    tuiHuoSJ: [], // 退货时间
                    gongYingShang: [required()], // 供应商
                    cangKuMC: [required()], // 仓库名称
                    yuanCaiGouJinE: [positiveEightTwo()], // 原采购金额
                    tuiHuoJinE: [positiveEightTwo()], // 退货金额
                    yingKuiJinE: [positiveEightTwo()], // 盈亏金额
                    heJiZhong: [digits()], // 合计（种）
                    tuiHuoYuanYin: [], // 退货原因备注
                    tuiHuoSL: [required(),positiveEightTwo()], // 退货原因备注

            }
            this.titlePrefix = '退货单';
            this.tuiHuoDeXiangQingsModel = {
                tuiHuoDanID: "", // 退货单ID
                shangPinMC: "", // 商品名称
                shangPinID: "", // 商品ID
                guiGe: "", // 规格
                huoHao: "", // 货号
                tiaoMa: "", // 条码
                danWei: "", // 单位
                chengBenJia: "0", // 成本价
                shengChanRQ: "", // 生产日期
                baoZhiQi: "", // 保质期
                tuiHuoSL: "0", // 退货数量
                chuKuJia: "", // 出库价
                tuiHuoSJ: "", // 退货时间
                xiaoJi: "", // 小计
                tuiHuoYuanYin: "", // 退货原因
            }
            this.defaultForm = {
                id: null,
                tuiHuoDanID: "", // 退货单ID
                caiGouDanID: "", // 采购单ID
                caoZuoRenXingMing: this.$store.getters.user.name, // 操作人
                caoZuoRenID: this.$store.getters.user.id, // 操作人ID
                tuiHuoSJ: "", // 退货时间
                gongYingShang: "", // 供应商
                cangKuMC: "", // 仓库名称
                yuanCaiGouJinE: "", // 原采购金额
                tuiHuoJinE: "", // 退货金额
                yingKuiJinE: "", // 盈亏金额
                heJiZhong: "", // 合计（种）
                tuiHuoYuanYin: "", // 退货原因备注
                tuiHuoDeXiangQings: [],
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        data() {
            return {
                options: [],
                gongHuoShangOptions: [],
                cGDXQOptions: [],
                chooseTable: [],
                isShowAddList: false,
                isSaveChoose: true,
            }
        },
        mounted() {
            this.getCKList(),
                this.getGHSList(),
                this.getCGDXQList()
        },
        methods: {
            handleClSelectCG(cls) {
                let cc = [];
                this.form.tuiHuoDeXiangQings.forEach(item=>cc.push(item.shangPinID));
                cls.forEach(cl => {
                    let obj = {};
                    if(!cc.includes(cl.shangPinID)){
                        this.form.caiGouDanID = cl.caiGouDanID || '';
                        obj.caiGouDanXiangQingID = cl.id || '';
                        obj.shangPinID = cl.shangPinID || '';
                        obj.shangPinMC = cl.shangPinMC || '';
                        obj.yiTuiHuoSL = cl.yiTuiHuoSL || '';
                        obj.caiGouSL = cl.caiGouSL || '';
                        obj.weiRuKuSL = cl.weiRuKuSL || '';
                        obj.jinHuoJia = cl.jinHuoJia || '';
                        obj.chengBenJia = cl.jinHuoJia || '';
                        obj.tuiHuoSL = cl.tuiHuoSL || '';
                        obj.guiGe = cl.guiGe || '';
                        obj.huoHao = cl.huoHao || '';
                        obj.tiaoMa = cl.tiaoMa || '';
                        obj.danWei = cl.danWei || '';
                        obj.shengChanRQ = cl.shengChanRQ || '';
                        obj.baoZhiQiTian = cl.baoZhiQiTian || '';
                        obj.gongHuoShang = cl.gongHuoShang || '';
                        obj.gongHuoShangID = cl.gongHuoShangID || '';
                        obj.chuKuJia = cl.jinHuoJia || '';
                        this.form.tuiHuoDeXiangQings.push(obj);
                    }

                });
            },
            maxReturnQuantity(row) {
                /*{{ maxReturnQuantity(scope.row) }}*/
                // 获取采购数量、已入库数量和已退货数量
                const caiGouSL = row.caiGouSL || 0;
                const yiRuKuSL = row.yiRuKuSL || 0;
                const yiTuiHuoSL = row.yiTuiHuoSL || 0;

                // 计算可退货数量的上限
                return caiGouSL - yiRuKuSL - yiTuiHuoSL;
            },
            // 获取仓库下拉选择列表
            getCKList() {
                getCangKuList().then((response) => {
                    this.options = response.data
                })
            },
            getGHSList() {
                getGongHuoShangList().then((response) => {
                    this.gongHuoShangOptions = response.data
                })
            },

        }
    }
</script>

<style scoped>
    table {
        border-collapse: collapse;
        width: 100%;
    }

    th, td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    th {
        background-color: #f2f2f2; /* 设置表头背景颜色 */
    }

    .highlighted {
        background-color: yellow; /* 设置醒目颜色 */
    }
</style>
